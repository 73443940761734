








































import { Vue, Component, Prop } from 'vue-property-decorator'
import { teamsStore } from '@/store'

@Component({
  name: 'SelectorInput',
})
export default class SelectorInput extends Vue {
  @Prop({ type: Number, default: null }) readonly value!: number
  @Prop({ type: Boolean, default: false }) readonly hideLabel!: boolean
  @Prop({ type: Boolean, default: false }) readonly hideCaption!: boolean

  inputValue: string | number | null = null

  created (): void {
    this.inputValue = this.value
  }

  get hint (): string {
    return teamsStore.getters.currentTeam?.taskImportanceRev
      ? this.$t('tasks.importanceCaption.descending').toString()
      : this.$t('tasks.importanceCaption.ascending').toString()
  }

  onSubmit (): void {
    this.$emit('input', this.inputValue === '' ? null : Number(this.inputValue))
  }

  validate (input: string): string | true {
    if (input === '') return true

    const typedInput = Number(input)
    if (typeof typedInput !== 'number') {
      return this.$t('common.validationErrors.number').toString()
    }
    if (!Number.isInteger(typedInput)) {
      return this.$t('common.validationErrors.integer').toString()
    }

    const team = teamsStore.getters.currentTeam

    const { taskImportanceMin: min, taskImportanceMax: max } = team
    if (
      typedInput < (min ?? 0) ||
      typedInput > (max ?? 0)
    ) return this.$t('common.validationErrors.between', { from: min, to: max }).toString()

    return true
  }
}
